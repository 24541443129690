import { createStore } from "vuex";

export default createStore({
  state: {
    user_config: null, // {rcd_id: xxx, name: xxx, email: xxx, type: user / admin / disabled, company_id: xxxx}
    system_access: null, // client_id, isSystemAdmin
    current_client_id: null, // selected by system admin
    client_id_list: null,
    current_client_config: null, // {post_categories, max_featured_posts}
  },
  getters: {},
  mutations: {
    mutateUserConfig(state, newObjDef) {
      state.user_config = newObjDef;
    },
    mutateSystemAccess(state, newValue) {
      state.system_access = newValue;
    },
    mutateCurrentClientId(state, newValue) {
      state.current_client_id = newValue;
    },
    mutateClientIdList(state, newValue){
      state.client_id_list = newValue
    },
    mutateCurrentClientConfig(state, newValue){
      state.current_client_config = newValue
    }
  },
  actions: {},
  modules: {},
});
