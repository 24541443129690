<template>
  <v-container class="fill-height">
    <v-row>
      <v-responsive></v-responsive>
      <v-col align-self="center" style="text-align:center">
        <v-sheet><h1 style="font-size:5rem">Welcome</h1></v-sheet>
      </v-col>
      <v-responsive></v-responsive>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";

// Components

export default defineComponent({
  name: "HomeView",
});
</script>
