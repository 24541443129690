// ------------ SWEET CMS BACKEND ------------
// ------------- PROD ------------------------
/* 
  firestore location: Singapore
*/

export const firebaseConfig = {
  // copy all these lines from "Add Firebase to your web app" setup guide.
  apiKey: "AIzaSyBwiFW125uiv6kPgur0RnMI3NMPFwFoe4w",
  authDomain: "cms-backend-prod-e2048.firebaseapp.com",
  projectId: "cms-backend-prod-e2048",
  messagingSenderId: "513452009753",
  // appId: "1:849965201060:web:f4c044e6bad101cad09831",
  // databaseURL: "https://direct-landing-385800-default-rtdb.firebaseio.com",
}