<template>
  <v-app>

    <v-app-bar app :elevation="2">
      <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer" ></v-app-bar-nav-icon>
      <v-toolbar-title>Sweet CMS</v-toolbar-title>
      <div v-if="isSystemAdmin" class="d-flex align-center h-100">
        <v-label>Client Id:</v-label>
        <v-select v-model="selected_client_id" density="compact" clearable :items="clientIDList" class="mx-5 mt-5" style="width:300px;"></v-select>
        <v-btn @click="refresh_clientIDList()" class="bg-success">Refresh Client List</v-btn>
      </div>
      <v-spacer></v-spacer>
      <!-- <v-btn variant="text" icon="mdi-account"></v-btn> -->
      <v-btn variant="text" icon="mdi-logout" @click.stop="signout" v-if="user_logged_in"></v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" location="left" temporary>
      <v-list>
        <v-list-item v-for="link in links" :key="link.text" router :to="link.route">
          <v-list-item-title class="white--text">{{link.text}}</v-list-item-title>
        </v-list-item>
        <v-list-item router to="/usermgmt" v-if="isAdmin">
          <v-list-item-title class="white--text">User Management</v-list-item-title>
        </v-list-item>
        <v-list-item router to="/contactformconfig">
          <v-list-item-title class="white--text">Contact Form Config</v-list-item-title>
        </v-list-item>
        <v-list-item router to="/contactlist">
          <v-list-item-title class="white--text">Contact List</v-list-item-title>
        </v-list-item>
        <v-list-item router to="/adminusermgmt" v-if="isSystemAdmin">
          <v-list-item-title class="white--text">System Admin User Mgmt</v-list-item-title>
        </v-list-item>
        <v-list-item router to="/DevConsole" v-if="isSystemAdmin">
          <v-list-item-title class="white--text">Dev Console</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>

  </v-app>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { fb_auth, fb_firestoreDB } from "@/main";
import { onAuthStateChanged } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { fb_funcs } from "@/main";
import { doc, getDoc, onSnapshot, query, collection, setDoc, getDocs } from "firebase/firestore";
export default {
  name: "App",
  setup() {
    const errMsg = ref(null)
    const router = useRouter();

    const drawer = ref(null)
    const links = [
      {text: "Posts", route: "/posts"},
    ]

    const store = useStore();
    const isAdmin = computed(()=> store.state.user_config?.role == "admin")
    const avatarURL = ref("")
    const user_id = ref("")
    const user_logged_in = ref(false)
    const user_logged_out = ref(false)


    const clientIDList = computed(()=> store.state.client_id_list || [])
    const selected_client_id = ref(null)
    const listClientIds = httpsCallable(fb_funcs, "listClientIds");
    function refresh_clientIDList () {
      listClientIds().then((res) => {
        store.commit("mutateClientIdList", res.data.client_id_list)
      }).catch((e) => {alert(e.message)});
    }
    watch(
      selected_client_id,
      async (new_client_id) => {
      if(selected_client_id.value){ 
        store.commit("mutateCurrentClientId", new_client_id)
        // load client config also from the backend firestore
        const docRef = doc(fb_firestoreDB, new_client_id + "/client_config");
        const docSnapshot = await getDoc(docRef)
        if (docSnapshot.exists()) {
          store.commit("mutateCurrentClientConfig", docSnapshot.data())
        } else {
          alert(`Client "${new_client_id}" need a proper configuration first.`)
        }
      }
    })

    // listener for user config
    let unsubUserConfig = () => {console.log("I'm original unsub")}

    const isSystemAdmin = computed(()=> store.state.system_access?.isSystemAdmin)

    onAuthStateChanged(fb_auth, async (user) => {
      // Set an authentication state observer and get user data
      if (user) {
        const uid = user.uid;
        user_id.value = uid // firebase auth uid
        user_logged_in.value = true
        user_logged_out.value = false
        avatarURL.value = user.photoURL
        // console.log("logged in!");
        const custom_user_claims = (await fb_auth.currentUser.getIdTokenResult()).claims
        // console.log("custom claim:", custom_user_claims)
        store.commit("mutateSystemAccess", {
          client_id: custom_user_claims?.client_id,
          isSystemAdmin: custom_user_claims?.isSystemAdmin,
        })
        selected_client_id.value = custom_user_claims?.client_id
        store.commit("mutateCurrentClientId", custom_user_claims?.client_id)
        // console.log("custom_user_claims: ", custom_user_claims);
        // --------------------
        // user config listener 1) get latest update 2) sign out user upon deactivation - user is disabled at server end from fb auth, but at client side a signout need to be inited.
        unsubUserConfig = onSnapshot(
          doc(fb_firestoreDB, custom_user_claims?.client_id + "/users/user_docs", uid),
          (docSnapShot) => {
            if(!docSnapShot.exists()){
              // net new user, raise support ticket. By default, a record should have existed.
              alert("Unknown user config. Please contact our support team.")
              signout()
            } else {
              // existing user config record, and this is fired every time there is an update pushed from db server side.
              // console.log("newly updated snapshot of user config: ", docSnapShot.data())
              if(docSnapShot.data()?.role=="disabled"){
                try { // sign out
                  alert("User is disabled. Signing out. ")
                  signout()
                } catch(e){
                  alert(`Error: ${e.code}\n${e.message}`)
                }
              } else {
                // user is still active. Updated user config is synced with Vuex for type and name etc.
                store.commit("mutateUserConfig", docSnapShot.data());
              }
            }
          },
          (e)=>{
            router.push({ name: "Login" });
            if (e.code=="permission-denied") {
              // this is also fired after user signed out by himself.
              if(user_logged_out.value){
                // user voluntarily log out. 
              }else{
                // only when system admin deactivate this user.
                alert("Your account have been deactivated by your system admin.")
              }
            } else {
              alert(`Error: ${e.code}\n${e.message}`)
            }            
          }
        )
        // listen to client id list query if user is system admin (from aria_it). 
        if(isSystemAdmin.value){
          refresh_clientIDList()
        }
      } else {
        // User is signed out
        user_id.value = ""
        user_logged_in.value = false
        user_logged_out.value = true
        router.push({ path: "/login"})
        // --------------- memorize the requested route for redirection after login ---------------
        // let requested_route = route.path == "/" || route.path.slice(0, 6) == "/login" ? "" : route.path.replaceAll("/", "---")
        // if(requested_route == ""){
        //   router.push({ path: "/login"})
        // } else {
        //   router.push({ path: "/login", query: {requested_route: requested_route}});
        // }
        // ------------------------------------------------------------------------------------------
      }
    });

    const signout = () => {
      fb_auth.signOut()
      unsubUserConfig()
      console.log("You signed out.")
      router.push({ path: "/login"})
    }

    // lifecycle event hooks
    onMounted(()=>{window.addEventListener("beforeunload", async ()=>{
      // NOTE: this is fired after user try to close browser tab / window, but there might not be enough time 
      unsubUserConfig();
      })});
      
    return {
      drawer,
      links,
      user_logged_in,
      isSystemAdmin,
      selected_client_id,
      clientIDList,
      refresh_clientIDList,
      isAdmin,
      signout
    }
  }
}
</script>

<style>
ol, ul {
  list-style-position: inside;
}

.drag-highlight {
  background-color: aqua;
}
.empty-field-prompt {
  background-color: lightpink;
}
</style>
